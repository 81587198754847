@import "../../Styles/main.scss";
.authLive_container{
  & .button-volver{
    
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}

@media only screen and (min-width: 992px) {
  // .MuiAppBar-colorPrimary{
  //     background-color: $primary-dark  !important;
  // }
  .modal-login {
    display: flex;
    justify-content: center;
    align-items: center;
    & .modal-content {
      background: white;
      //height: 50%;
      border-radius: 6px;

      padding: 2rem;
      font-family: "Nunito";
      & .title {
        // margin-bottom: 2.5rem;
        font-size: 2rem;
        text-align: center;
      }
      & .container-types {
        & .button-type-user {
          width: 100%;
          margin-bottom: 1rem;
          font-family: "Nunito-Bold";
          display: block;
        }
      }
      & .container_options{
        & .btn-register{
          margin-bottom: 1rem;
          margin-top: 2rem;
          width: 100%;

        }
        & .btn-cancelar{

        }
      }
    }
    hr {
      margin: 2rem 0;
    }
  }
}

@media only screen and (max-width: 991px) {
  // .MuiAppBar-colorPrimary{
  //     background-color: $primary-dark  !important;
  // }
  .modal-login {
    width: 100%;
    height: 80%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    background: white;
    & .modal-content {
      background: white;
      height: 100%;
      border-radius: 0 0 25px 25px;
      text-align: center;
      padding: 2rem 2rem 0;
      width: 100%;
      
      & .content {
        & .title {
          margin-bottom: 2.5rem;
        }
        //padding-top: 4rem;
        & .container-types {
          & .button-type-user {
            width: 100%;
            margin-bottom: 1rem;
            font-family: "Nunito-Bold";
            display: block;
          }
        }
       
        & .container_options{
          & .btn-register{
            margin-bottom: 1rem;
            width: 100%;
            margin-top: 2.5rem;
          }
          & .btn-cancelar {
          }
        }
        
      }
    }
  }
}
