.sucursales-container {
  margin: 0 auto;
  width: 80%;
  margin-top: 60px;
  &_title {
  }
  &_options {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 30px;
  }
  &_content {
    width: 100%;
    & .paper {
      width: 100%;
      height: 100%;
      padding: 15px;
      & p {
        margin: 0;
        padding: 5px;
        margin-bottom: 1rem;
      }
    }
  }
   
}
.modal-content-eliminar {
  position: relative;
  background: white;
  height: 50%;
  padding: 2rem;
  width: 60%;
  .date-options {
    display: flex;
    justify-content: space-evenly;
    position: absolute;
    bottom: 33px;
    left: 0;
    width: 100%;
  }
  .button-add {
    width: 100%;
  }
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 991px) {
  .sucursales-container {
    margin: 0 auto;
    width: 95%;
    margin-top: 30px;
  }
}