.loader--container{
    background-color: #00000080;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;


    .MuiCircularProgress-indeterminate{
        width: 80px !important;
        height: 80px !important;
        z-index: 101 !important;
        color: #e6e6e6 !important;
    }
   
}
