.profile_public-page {
  display: flex;
  margin: 0.5rem auto;
  width: 80%;
  .profile_photo {
    margin: 0.5rem;
    -webkit-box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
      0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
      0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
      0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    border-radius: 5px;
    min-height: 400px;
    min-width: 300px;
    padding: 1rem;

  }
  .profile_data {
    margin: 0.5rem;
    -webkit-box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
      0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
      0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
      0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    border-radius: 5px;
    padding: 1rem;
    min-height: 400px;
    min-width: 300px;
    .btn-upload{
      margin-top: 40%;
      & .MuiButton-label{
      font-size: 10px;
      }
    }
  }
  & .band_no_verify {
    width: 100%;
    height: 50px;
    background-color: black;
    color: red;
  }
  &--photo-portada {
    width: 80%;

    height: 15vh;
    figure {
      margin: 0 1rem 0 0;
      position: absolute;
      width: 80%;
    }
    img {
      width: 100%;
      height: 15vh;
    }
    a {
      position: absolute;
      bottom: 10px;
      right: 10px;
      padding: 2px 10px;
      background: white;
      border: 1px solid black;
      border-radius: 6px;
      cursor: pointer;
      text-decoration: none;
      color: black;
      &:hover {
        color: black;
      }
    }
  }
  &--container {
    display: flex;
    width: 80%;
    margin: 3rem auto;
    .profile {
      &--col1 {
        width: 30%;
        .profile--img--container {
          height: 200px;
          figure {
            margin: 0 1rem 0 0;
            position: absolute;
            width: 200px;
          }
          img {
            width: 100%;
            height: 200px;
          }
          a {
            position: absolute;
            bottom: 10px;
            right: 10px;
            padding: 2px 10px;
            background: white;
            border: 1px solid black;
            border-radius: 6px;
            cursor: pointer;
            text-decoration: none;
            color: black;
            &:hover {
              color: black;
            }
          }
        }
      }
      &--col2 {
        width: 70%;
        .MuiFormControl-root {
          margin: 1rem;
        }
        .button--save {
          position: absolute;
          right: 0;
          top: 0;
        }
      }
    }
  }
  & .how_show {
    width: 300px;
    & .provider--card--container {
      & .MuiPaper-root {
        width: 300px;
        height: 290px;
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .profile--container {
    //display: flex;
    //width: 80%;
    margin: 3rem;
    .profile {
      &--col1 {
        //width: 30%;
        img {
          width: 100%;
          height: 200px;
        }
      }
      &--col2 {
        .MuiFormControl-root {
          margin: 1rem;
        }
      }
    }
  }
}
