@media only screen and (max-width: 991px) {
  .profile--container {
    //display: flex;
    //width: 80%;
    margin: 3rem;
    .profile {
      &--col1 {
        //width: 30%;
        img {
          width: 100%;
          height: 200px;
        }
      }
      &--col2 {
        .MuiFormControl-root {
          margin: 1rem;
        }
      }
    }
  }
}

@media only screen and (min-width: 992px) {
  #scrollable-auto-tabpanel-0{
    .MuiBox-root {
      padding: 0 !important;
    }
  }
 

 
}
