.crear-sucursal {
  margin: 60px auto;
  width: 80%;
  //margin-top: 60px;
  &_title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &_content {
    .paper {
      width: 100%;
      height: 100%;
      padding: 15px;
      & .form {
        margin: 30px;
        .col {
          &-1 {
            width: 40%;
            & .input-form {
              display: block;
              margin: 15px auto;
            }
            & .button {
              margin: 15px auto;
            }
          }
        }
      }
      & .options {
        display: flex;
        justify-content: flex-end;
        & .button{
            margin: auto 30px;
            width: 140px;
        }
      }
      & p {
        margin: 0;
        padding: 5px;
        margin-bottom: 1rem;
      }
    }
  }
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 991px) {
  .crear-sucursal {

    width: 95%;
    &_title {
      display: block;
      p{
        text-align: right;
        padding-right: 1rem;
      }
    }
    &_content {
      .paper {
       padding: 15px 0px;
        & .form {
          display: block;
          .col{
            &-1,&-2{
              width: 100%;
            }
          }
        }
      }
    }
  }
}