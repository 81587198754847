.administrator-gallery {
  margin: 2rem auto;
  width: 60%;
  .section-upload {
    background-color: grey;
    width: 100%;
    height: 100px;
    border-radius: 6px;
    margin-bottom: 2rem;
  }
  .section-gallery {
    //box-shadow: 0px 6px 4px 2px #aaaaaa;
    border:1px solid grey;
    width: 100%;
    min-height: 300px;
    border-radius: 6px;
    .header{
        display: flex;
        align-items: center;
        justify-content: center;
        h4{
            margin: 0;
            padding:.5rem;
        }
    }
  }
}
