.profile-page {
  margin: 0.5rem auto;
  width: 80%;
  & .band_no_verify {
    width: 100%;
    height: 50px;
    background-color: black;
    color: red;
  }
  &--photo-portada {
    width: 80%;

    height: 15vh;
    figure {
      margin: 0 1rem 0 0;
      position: absolute;
      width: 80%;
    }
    img {
      width: 100%;
      height: 15vh;
    }
    a {
      position: absolute;
      bottom: 10px;
      right: 10px;
      padding: 2px 10px;
      background: white;
      border: 1px solid black;
      border-radius: 6px;
      cursor: pointer;
      text-decoration: none;
      color: black;
      &:hover {
        color: black;
      }
    }
  }
  &--container {
    display: flex;
    //width: 80%;
    margin: 0.5rem auto;
    .profile {
      &--col1 {
        //swidth: 30%;
        margin: 0.5rem;
        -webkit-box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
          0px 1px 1px 0px rgba(0, 0, 0, 0.14),
          0px 1px 3px 0px rgba(0, 0, 0, 0.12);
        -moz-box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
          0px 1px 1px 0px rgba(0, 0, 0, 0.14),
          0px 1px 3px 0px rgba(0, 0, 0, 0.12);
        box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
          0px 1px 1px 0px rgba(0, 0, 0, 0.14),
          0px 1px 3px 0px rgba(0, 0, 0, 0.12);
        border-radius: 5px;
        padding: 1rem;
        .profile_container {
          & .btn-container {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            margin: .5rem auto;
            label{
              width: 100%;
            }
          }
          & .profile--img--container {
            display: flex;
            justify-content: center;
            align-items: center;
            figure {
              width: 100%;
              margin: 0;
            }
            img {
              width: 200px;
              height: 200px;
            }
          }
        }
      }
      &--col2 {
        width: 70%;
        margin: 0.5rem;
        .container {
          position: relative;
        }
        .title {
          h3 {
            margin: 1rem;
          }
        }
        -webkit-box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
          0px 1px 1px 0px rgba(0, 0, 0, 0.14),
          0px 1px 3px 0px rgba(0, 0, 0, 0.12);
        -moz-box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
          0px 1px 1px 0px rgba(0, 0, 0, 0.14),
          0px 1px 3px 0px rgba(0, 0, 0, 0.12);
        box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
          0px 1px 1px 0px rgba(0, 0, 0, 0.14),
          0px 1px 3px 0px rgba(0, 0, 0, 0.12);
        border-radius: 5px;
        padding: 2rem;
        .MuiFormControl-root {
          margin: 1rem;
        }
        .button--save {
          position: absolute;
          right: 0;
          top: 0;
        }
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .profile--container {
    //display: flex;
    //width: 80%;
    margin: 3rem;
    .profile {
      &--col1 {
        //width: 30%;
        img {
          width: 100%;
          height: 200px;
        }
      }
      &--col2 {
        .MuiFormControl-root {
          margin: 1rem;
        }
      }
    }
  }
}
