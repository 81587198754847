@import 'Styles/main';
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans+Condensed:wght@300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;600;700;800&display=swap');
.App-container{
  background-color: $white;
//  font-family: 'Fira Sans Condensed', sans-serif;
font-family: 'Nunito', sans-serif;
}

@media only screen and (max-width: 991px) {
    .App-container-body{
      margin-top: 4rem;
    }
}