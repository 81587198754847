.DashboardProfessional_container {
  display: block;
  .MuiTableCell-root {
    padding: 0.2rem .5rem;
  }
  .panel {
    -webkit-box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
      0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
      0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
      0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    // box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    //   0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    border-radius: 5px;
    width: 100%;
    margin-bottom: 1rem;
    .title {
      padding: 1rem;
      h4 {
        margin: 0;
      }
      .mobile{
        display: none;
      }
    }
    .panel_container__open {
      display: flex;
      background-color: transparent;
      //   xwidth: 100%;
      padding: 16px;
      justify-content: space-between;

      Button {
        min-width: 200px;
      }
    }
  }
  .section_graph {
    display: flex;
    //padding: 25px;
    -webkit-box-shadow: 0px 0px 10px -1px rgba(0, 0, 0, 0.53);
    -moz-box-shadow: 0px 0px 10px -1px rgba(0, 0, 0, 0.53);
    box-shadow: 0px 0px 10px -1px rgba(0, 0, 0, 0.53);
    border-radius: 15px;
    width: 100%;
    padding: 1rem;
  }
}
@media only screen and (max-width: 991px) {
  .DashboardProfessional_container {
    margin: 0 1rem;
    h1{
      margin: 1rem 0 .5rem 0;
    }
    .panel{
      border-radius: 0;
      .title{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .mobile{
          display: block;
        }
      }
     
      .panel_container__open, .panel_container__close{
        display: block;
        padding: 16px;
        padding-top: 0;
        Button{
          width: 100%;
          margin:.3rem 0;
        }
      }
      .panel_container__close{
        display: none;
      }
    }
  }
}