.dashboard-clinica {
  width: 80%;
  margin: 0 auto;

  &_metrics {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    & > div {
      margin:0.5rem
    }
  }

  &_parent {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 45px;
    grid-row-gap: 45px;
    margin: 30px;
    margin-top: 0px;
    margin-left: 0;
    padding-bottom: 60px;

    & .paper {
      width: 100%;
      height: 100%;
      padding: 15px;

      & p {
        margin: 0;
        padding: 5px;
        margin-bottom: 1rem;
      }

      // & .recharts-wrapper{
      //     width: 100% !important;
      //     height: 100% !important;
      //     & .recharts-surface{
      //         width: 100% !important;
      //         height: 100% !important;
      //     }
      // }

    }

    &__events {
      //border: 1px solid black;

      grid-area: 1 / 1 / 2 / 4;
    }

    &__sales {
      // border: 1px solid black;

      grid-area: 1 / 4 / 2 / 6;
    }

    &__inventory {
      // border: 1px solid black;

      grid-area: 2 / 1 / 3 / 4;
    }

    &__clients {
      // border: 1px solid black;

      grid-area: 2 / 4 / 3 / 6;
    }
  }

  &_title {

    // grid-area: 1 / 1 / 2 / 6;
    // border: 1px solid black;
    h1 {
      font-weight: 500;
      margin: 15px auto;
    }
  }

}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 991px) {
  .dashboard-clinica {
    margin-top: 30px;

    &_title {}

    &_events {}

    &_sales {}

    &_inventory {}

    &_clients {}

    &_parent {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      grid-template-rows: repeat(2, 1fr);
      grid-column-gap: 45px;
      grid-row-gap: 45px;
      margin: 30px;
      margin-top: 0px;
      margin-left: 0;
      padding-bottom: 60px;

      & .paper {
        width: 100%;
        height: 100%;
        padding: 15px;

        & p {
          margin: 0;
          padding: 5px;
          margin-bottom: 1rem;
        }

        // & .recharts-wrapper{
        //     width: 100% !important;
        //     height: 100% !important;
        //     & .recharts-surface{
        //         width: 100% !important;
        //         height: 100% !important;
        //     }
        // }

      }

      &__events {
        //border: 1px solid black;

        grid-area: 1 / 1 / 1 / 6;
      }

      &__sales {
        // border: 1px solid black;

        grid-area: 2 / 1 / 2 / 6;
      }

      &__inventory {
        // border: 1px solid black;

        grid-area: 3 / 1 / 3 / 6;
      }

      &__clients {
        // border: 1px solid black;

        grid-area: 4 / 1 / 4 / 6;
      }
    }
  }
}