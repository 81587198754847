.pet-page {
  margin: .5rem auto;
  width: 80%;
  display: flex;
  .pet_list {
    width: auto;
    height: 100%;
    padding: 0.5rem;
    margin: 0.5rem;
    -webkit-box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
      0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
      0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
      0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    border-radius: 5px;
  }
  .pet_info {
    width: auto;
    height: 100%;
    padding: 0.5rem;
    margin: 0.5rem;

    -webkit-box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
      0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
      0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
      0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    border-radius: 5px;
  }
  .pet_preview{
    width: 200px;
    height: 200px;
    margin: 0.5rem;
  }
  .pet_btn_upload{
    margin: .5rem;
    width: 200px;
    .btn_upload{
      width: inherit;
    }
  }
  .pet_actions{
    display: flex;
    justify-content: flex-end;
    padding: 10px;
    Button{
      margin-left: 1rem;
    }
    .btn-delete{
      background-color: red;
      color:white;
    }
    .btn-success{
      background-color: rgb(0, 186, 0);
      color:white;

    }
  }
  #select-label{
    background-color: white;
  }
}

@media only screen and (max-width: 991px) {
  .pet-page {
    //display: flex;
    //width: 80%;
    margin: 3rem;
  }
}
