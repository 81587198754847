.page-init {
  .alert-disabled-user {
    margin-top: 1rem;
    width: 100%;
    height: 100px;
    background-color: #edecec;
    border-radius: 5px;
    border: 1px solid red;
    border-style: dashed;
    display: flex;
    align-items: center;
    .container {
      padding-left: 1rem;
      width: 100%;
      .title {
        width: 100%;
        display: flex;
        align-items: center;
        h4 {
          margin-top: 0.5rem;
          margin-bottom: 0.5rem;
        }
      }
      p{
        margin: 0 auto;
      }
      a {
        color: rgb(206, 137, 10);
        font-weight: 600;
      }
      a:hover {
        color: rgb(255, 176, 30);
        cursor: pointer;
        font-weight: 600;
      }
    }
  }
  & .news_banner{
    width: 100%;
    border: 1px solid black;
    height: 150px;
    background-color: orange;
    color: white;
    &-content{
      margin:1rem;
    }
  }
  & .tiendas_destacadas, .clinicas_destacadas, .promos{
    display: flex;
    justify-content: space-around;
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (max-width: 991px) {
  .page-init{
    width: 95%;
    margin: 0 auto;
    .alert-disabled-user {
      //margin: .5rem;
      width: 100%;
      height: 100%;
      background-color: #edecec;
      border-radius: 5px;
      border: 1px solid red;
      border-style: dashed;
      display: flex;
      align-items: center;
      .container {
        padding-left: 1rem;
        width: 100%;
        .title {
          width: 100%;
          display: flex;
          align-items: center;
          h4 {
            margin-top: 0.5rem;
            margin-bottom: 0.5rem;
          }
        }
        p{
          margin: 0 auto;
        }
        a {
          color: rgb(206, 137, 10);
          font-weight: 600;
        }
        a:hover {
          color: rgb(255, 176, 30);
          cursor: pointer;
          font-weight: 600;
        }
      }
    }
  
  }
  & .tiendas_destacadas, .clinicas_destacadas, .promos{
    display: block !important;
    justify-content: space-around;
  }
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .page-init {
    width: 80%;
    margin: 0 auto;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1441px) {
  .page-init {
    width: 60%;
    margin: 0 auto;
  }
}
