@import "../../Styles/main.scss";

.profile-clinica-container {
  background-color: $primary;
  color: $black;

  .body {
    margin: 2rem;

    & .section {
      &__sections {
        &--desktop {
          ul {
            list-style: none;
            display: flex;
            justify-content: space-between;
            padding: 0;
            margin-bottom: 2rem;

            li {
              height: 100%;
              border-bottom: .5px solid black;
              min-width: 150px;
              text-align: center;
              //padding: 1rem;
              padding-bottom: 1rem;
              padding-top: 1rem;

              a {
                text-decoration: none;
                color: black;
                padding: 2rem;
                padding-bottom: 1rem;
              }

              &:hover {
                background-color: rgb(202, 202, 202);
                cursor: pointer;
              }
            }
          }
        }
        &--mobile{
          display: none;
        }

      }

      &__description {
        margin: 2rem auto;
        display: flex;
        justify-content: center;

        & .content {
          max-width: 70%;

          p {
            padding: .5rem 1rem;
            border-radius: 6px;

          }
        }


      }

      &_agenda {
        background-color: white;
        border-radius: 6px;
        padding: 2rem;
        margin: 1rem;
        text-align: center;
        //background-color: rgb(177, 177, 177);
        & .header {
          display: flex;
          align-items: center;

          h4 {
            margin: 0;
          }
        }

        & .content {
          &.expand {
            min-height: 425px;
            background-color: white;
            display: block;
            width: 100%;
            //margin-left: 20%;
          }

          &.collapse {
            display: none;
          }

          .options {
            display: flex;
            justify-content: space-between;
            height: 100%;
            min-height: 425px;

            .option {
              width: 33%;
              border: 1px solid black;
              border-radius: 6px;
              height: 100%;
              min-height: 425px;

              &_header {
                padding: .5rem;
              }
            }
          }
        }

        & .fc .fc-toolbar.fc-header-toolbar {
          margin: 0;
        }
      }

      &_map {
        padding: 2rem;
        background: white;
        margin: 1rem;
        border-radius: 6px;

        .header {
          padding-bottom: 1rem;

          h4 {
            margin: 0;
          }
        }

        & .content {
          display: flex;
          justify-content: space-around;
        }
      }

      &_gallery {
        padding: 2rem;
        background: white;
        margin: 1rem;
        border-radius: 6px;

        .header {}

        .content {
          .gallery {
            & .photo {
              min-width: 300px;
              min-height: 400px;
            }
          }
        }
      }
    }
  }

  .options {
    display: flex;
    justify-content: space-between;
    padding: 1rem;

    .button-back {
      background-color: $secondary-dark;
      color: $black;
      padding: 10px 40px;
    }
  }

  .title {
    //display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    h1 {
      margin: 0;
    }

    h6 {
      margin: 0;
      margin-bottom: 30px;
    }
  }

  .photo-portada {
    width: 100%;
    height: 250px;
      background-size: cover;
    & img{
      background-image: url(https://mipetnow-bck.s3.us-west-2.amazonaws.com/users/61f2480…/pic_portada/image-1643270502660.jpeg);
      // background-position-y: 46.6974%;
      background-size: cover;
      // width: 100%;
      // height: 100%;
    }
  }

}

.modal_content_message {
  padding: 2rem;

  .emisor {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 1rem;
    margin-bottom: 1rem;

  }

  .mensaje {
    margin-top: 1rem;
    margin-bottom: 1rem;

    textarea {
      width: 100%;
      height: 100px;
    }
  }

  .options {
    margin-top: 2rem;
    // margin-bottom: 1rem;
    display: flex;
    justify-content: flex-end;

    p {
      margin: auto 2rem;
      cursor: pointer;
    }

    Button {
      margin: auto 2rem;

    }
  }
}

@media only screen and (max-width: 991px) {
  .profile-clinica-container {
    .body {
      & .section {
        &__sections {
          &--mobile{
            display: block;
           
            ul {
              list-style: none;
              display: flex;
              justify-content: space-between;
              padding: 0;
              margin-bottom: 2rem;
            li {
              margin: 0;
              a {
                padding: 0;
                text-decoration: none;
                color: black;
                
              }
            }
          }
          }
          &--desktop{
            display: none;
          }
          

        }
      }
    }
  }
}