.center-message{
    width: 80%;
    margin: 2rem auto;
    display: flex;
    & Paper{
        width: 100%;
        margin-bottom: 2rem;
    }
    &_chats{}
    &_chat_live{
        width: 100%;
        position: relative;
        .title{
            display: flex;
            background-color: grey;
        }
        .content{

        }
        .options{
            position: absolute;
            bottom: 0;
            width: 100%;
        }
    }
}