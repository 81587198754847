.searchbar_container {
  width: 100%;
  height: 70px;
  background-color: white;
  display: flex;
  align-items: center;
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.15);

  &__content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;

    //margin: 0 1rem;
    &--inputs {
      // margin-left: 1rem;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;

      & .content-input {
        width: 100%;
        margin-left: 1.5rem;

        & .MuiOutlinedInput-input {
          padding: 11.5px 7px 13px;
          background-color: white;
        }

        & .MuiSelect-outlined.MuiSelect-outlined {
          background-color: white;

        }
      }
    }
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .searchbar_container {}
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .searchbar_container {}
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .searchbar_container {}
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (max-width: 991px) {
  .searchbar_container {
    width: 100%;
    height: 55px;
    background-color: white;
    box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.15);
    -webkit-box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.15);
    &__content{
      margin-right: 1rem;

    }
    & .MuiTextField-root{
      border-radius: 14px;
      min-width: 115px;
      min-height: 27px;
      height: 27px;
      & .MuiFormLabel-root{
        transform: translate(14px, 7px) scale(1) !important;
      }
      & .input-text-field-label{
        & .MuiInputLabel-outlined{
          transform: translate(14px, 7px) scale(1) !important;
        }
      }
      & .MuiOutlinedInput-root{
        border-radius: 14px;
          min-width: 115px;
          min-height: 27px;
          height: 27px;
        & #input-text-field {
          border-radius: 14px;
          min-width: 115px;
          min-height: 27px;
          height: 27px;
        }
      }
      
    }
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .searchbar_container {
   
  }
}