.card-promo_container {
  width: 215px;
  height: 260px;
  border: 1px solid black;
  border-radius: 6px;
  position: relative;
  &-title {
    position: absolute;
    top: 0;
    width: 100%;
    p {
      padding-left: 10px;
    }
  }
  &-body {
    position: absolute;
    padding: 20px;
    margin-top: 25%;
    height: 60%;
  }
  &-option {
    position: absolute;
    bottom: 0px;
    width: 100%;
    Button {
      border-radius: 6px;
    }
  }
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .card-promo_container {
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .card-promo_container {
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .card-promo_container {
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (max-width: 991px) {
  .card-promo_container {
    width: 100%;
    height: 95px;
    border: 1px solid black;
    border-radius: 6px;
    margin-bottom: .5rem;
    &-title {
      position: absolute;
      top: 0;
      width: 100%;
      p {
        padding-left: 10px;
        margin: .5rem 0;
      }
    }
    &-body {
      position: absolute;
      padding: 20px;
      margin-top: 0;
      //height: 60%;
    }
    &-option {
      position: absolute;
      bottom: 0px;
      width: 100%;
      Button {
        border-radius: 0 0 6px;
      }
    }
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .card-promo_container {
  }
}
