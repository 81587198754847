.exploreclinicas {
  &__content {
    max-width: 80%;
    margin: 1rem auto;

    & h2 {
      margin: 2rem 0;
    }

    &-body {
      & .content-body__data {
        display: flex;
        justify-content: space-between;

        &-skeleton {
          width: 100%;
          height: 500px;
          display: flex;
        }
      }
    }
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {}

/* Medium devices (laptops/desktops, 991px and down) */
@media only screen and (max-width: 991px) {
  .exploreclinicas {
    &__content {
      max-width: 95%;
      margin: 1rem auto;
  
      & h2 {
        margin: 2rem 1rem;
      }
  
      &-body {
        & .content-body__data {
          display: flex;
          justify-content: center;
  
          &-skeleton {
            width: auto;
            height: 500px;
            display: block;
            span {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {}