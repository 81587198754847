.modal-event{
    width: 100%;
    height: 90%;
    background-color: white;
    & .modal-content {
        background: white;
        height: 100%;
        border-radius: 0 0 25px 25px;
        text-align: center;
        padding: 2rem 2rem 0;
      }
}