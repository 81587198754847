.calendar-center-container {
  margin: 1rem 10%;
  text-align: center;
}
@media only screen and (max-width: 991px) {
  .modal-calendar-add {
    width: 100%;
    height: 80%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    background: white;
    & .modal-content {
      background: white;
      height: 100%;
      border-radius: 0 0 25px 25px;
      text-align: center;
      padding: 2rem 2rem 0;
    }
  }
}
@media only screen and (min-width: 992px) {
  .modal-calendar-add {
    display: flex;
    justify-content: center;
    align-items: center;
    & .modal-content {
        position: relative;
      background: white;
      height: 50%;
      padding: 2rem;
      width: 60%;
      .date-container{
        display: flex;
        justify-content: center;
        align-items: center;
        p{
            margin: 0 1rem;
        }
      }
      h2{
          text-align: center;
      }
      .date-list{
        display: flex;
        justify-content: center;
        & .itemList{
            margin: .5rem;
            border: 1px solid cyan;
            background-color: lightblue;
        }
      }
      .date-options{
        display: flex;
        justify-content: space-evenly;
        position: absolute;
        bottom: 33px;
        left: 0;
        width: 100%;
      }
      .button-add {
        width: 100%;
      }
    }
  }
}
