.dashboard {
    margin: 2rem auto;

    &_fundaciones_section {
        width: 80%;
        margin: auto;

        & .banner {
            & img {
                width: 100%;
            }
        }

        & .container {
            padding: 1rem 2rem;
            display: flex;

            & .content {
                width: 100%;
                margin: 0 2rem;
                display: flex;
                justify-content: space-between;

                & .card {
                    width: 30%;
                    border: 1px solid black;
                    border-radius: 6px;
                    height: 150px;

                    &:hover {
                        box-shadow: 0px 0px 25px 3px rgba(0, 0, 0, 0.39);
                        -webkit-box-shadow: 0px 0px 25px 3px rgba(0, 0, 0, 0.39);
                        -moz-box-shadow: 0px 0px 25px 3px rgba(0, 0, 0, 0.39);
                    }
                }
            }
        }
    }
}

@media screen and (max-width:991px) {

    .dashboard {
        &_fundaciones_section {
            width: 100%;
            margin: auto;

            & .banner {
                & img {
                    width: 100%;
                }
            }
        }

    }
}