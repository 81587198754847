.empleados-profesionales-container {
  margin: 0 auto;
  /* width: 80%; */
  /* margin-top: 60px; */
  height: 100vh;
  background-color: #e6e6e6;
  & .text-alert-no-sucursales{
    & strong{
      color: blue;
      font-weight: 600;
      cursor: pointer;
    }
  }
  &_title {
  }
  &_options {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 30px;
    margin-top: 0;
  }
  &_content {
    width: 80%;
    margin: 0 auto;
    padding-top: 60px;
    &--table{
      margin: 0 auto;
      height: 100%;
    }
    & .paper {
      width: 100%;
      height: 100%;
      padding: 15px;
      //background-color: rgb(230, 230, 230);
      & p {
        margin: 0;
        padding: 5px;
        margin-bottom: 1rem;
      }
      
      
    }
    & .table-row{
      &:hover{
        background-color: #f2f2f2;
        cursor: pointer;
      }
    }
  }
  
  
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 991px) {
  .empleados-profesionales-container{
    margin: 0 auto;
    width: 95%;
    margin-top: 30px;
    &_options{
      margin: 0;
    }
    &_content{
      & .paper{
        width: auto;
      }
      & .container-cards{
        margin-top: 30px;
        // div{
        //   margin: 30px 0;
        // }
        
      }
    }
  }
}