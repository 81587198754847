.calendar-page {
  &-container {
    margin: 30px auto;
    width: 80%;
    padding-bottom: 60px;
    &__title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 30px auto;
    }
    &__content {
      width: 100%;
      .paper {
        width: 100%;
        height: 100%;
        padding: 15px;
        margin-bottom: 30px;
        & .select_sucursal {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .sucursal {
            width: 50%;
          }
        }
        & .select_profesional {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .profesional {
              width: 50%;
            }
          }
      }
    }
    &__options {
    }
  }
}
