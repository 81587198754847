.share-qr-container{
    
    margin-top: 5rem;
    &__title,
    &__qr{
        display: flex;
    justify-content: center;
    align-items: center;
    }
    &__title{
        margin-bottom:2rem;
    }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (laptops/desktops, 991px and down) */
@media only screen and (max-width: 991px) {
    .share-qr-container{
    
        margin-top: 2rem;

        &__title,
        &__qr{
            display: flex;
        justify-content: center;
        align-items: center;
        }
        &__title{
            margin: 0 1rem;
            margin-bottom: 1rem;
            h1{
                font-size: 1.5rem;
                text-align: center;
            }
        }
    }
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}