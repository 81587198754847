@keyframes changedAnimation {
  from {
    background-color: #7dff7dc2;
  }

  to {
    background-color: white;
  }
}

.profile-comercial_public-page {
  //display: flex;
  justify-content: center;
  width: 100%;
  margin: 2rem auto;

  & .title-description {
    margin: 1rem auto;
    width: 100%;
    text-align: center;

  }

  .profile_photo {
    margin: 0.5rem;
    -webkit-box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
      0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
      0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
      0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    border-radius: 5px;
    min-height: 380px;
    min-width: 300px;
    padding: 1rem;

  }

  .profile_data {
    margin: 0.5rem;
    -webkit-box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
      0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
      0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
      0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    border-radius: 5px;
    padding: 1rem;
    min-height: 380px;
    min-width: 300px;
    width: 90%;
    margin: 2rem auto;

    &__title {
      // text-align: center;
      margin: .5rem auto;
      width: 100%;
      // display: flex;
      // justify-content: center;
      // p{
      //   text-align: justify;
      // }
      // strong{
      //   text-transform: capitalize;
      // }
      text-align: center;

      h1 {
        margin: 0;
      }

      h6 {
        margin: 0;
        margin-bottom: 30px;
      }

    }

    &__photo-portada {
      width: 100%;
      height: 197.25px;
      position: relative;

      & .img {
        width: 100%;
        height: 197.25px;
        background-size: cover;
        //background-position: 50%;

        &:hover {
          filter: contrast(0.5);

        }

      }

      .tooltiptext {
        visibility: hidden;
        min-width: 300px;
        width: auto;
        background-color: rgb(255, 255, 255);
        color: rgb(0, 0, 0);
        text-align: center;
        padding: 15px 15px;
        border-radius: 6px;

        /* Position the tooltip text - see examples below! */
        position: absolute;
        bottom: 50px;
        right: 0;
        z-index: 0;
      }

      &:hover {
        cursor: pointer;

        .tooltiptext {
          visibility: visible;
          position: absolute;
          left: 0;
          right: 0;
          margin-left: auto;
          margin-right: auto;
          width: 100px;

          /* Need a specific value to work */
          &:hover {}
        }

      }
    }

    &__sections {
      ul {
        list-style: none;
        display: flex;
        justify-content: space-between;
        padding: 0;
        margin-bottom: 2rem;

        li {
          padding: 1rem;
          border-bottom: .5px solid black;
          min-width: 100px;
          text-align: center;

          a {
            text-decoration: none;
            color: black;
            padding: 2rem;
            padding-bottom: 1rem;
          }

          &:hover {
            background-color: rgb(202, 202, 202);
            cursor: pointer;
          }
        }
      }
    }

    &__description {
      margin: 2rem auto;
      display: flex;
      justify-content: center;

      // span{ display: flex;}
      & .container-editable {
        width: 50%;
        @media only screen and (max-width:991px) {
          width: 90%;
          & .options {
            display: block !important;
            // justify-content: flex-end;
            Button{
              margin-left: 0 !important;
              margin-top: 1rem;
              width: 100%;
            }
            
          }
        }
        & .input {
          display: block;
        }

        & .options {
          display: flex;
          justify-content: flex-end;
          Button{
            margin-left: 2rem;
            margin-top: 1rem;
          }
          
        }
      }

      & .changed {
        background-color: #7dff7dc2;
        animation-name: changedAnimation;
        animation-duration: 3s;
      }

      p {
        padding: .5rem 1rem;
        border-radius: 6px;

        &:hover {
          cursor: pointer;
          background-color: rgba(135, 135, 135, 0.576);
          border-radius: 6px;
          padding: .5rem 1rem;
        }
      }
    }

    &__form-calendar {
      width: 100%;
      // display: flex;
      justify-content: center;
      margin: 2rem auto;
      text-align: center;
      h3{
        margin-bottom: 0;
      }
      p{
        margin-top: 0;
        
      }
      &-edit {
        display: flex;
        justify-content: center;
        .mode,
        .days,
        .confirm {
          margin: 1rem;
          border-radius: 6px;
          min-width: 200px;
          max-width: 200px;
          min-height: 230px;
          box-shadow: 10px 10px 5px -5px rgba(0, 0, 0, 0.46);
          -webkit-box-shadow: 10px 10px 5px -5px rgba(0, 0, 0, 0.46);
          -moz-box-shadow: 10px 10px 5px -5px rgba(0, 0, 0, 0.46);
          border: 1px solid rgba(0, 0, 0, 0.56);
          padding: 1rem;


        }
        
      }
      @media only screen and (max-width:991px){
        &-edit {
          display: flex;
          overflow-x: auto;
          justify-content: flex-start;
        .days{
          
        }
      }
      }
    }

    &__location {
      width: 100%;
      display: flex;
      justify-content: space-around;
      margin: 2rem auto;
    }

    &__gallery {
      width: 100%;
      margin: 2rem auto;
    }

    &__recomendaciones {}







    &__form_input {
      margin-bottom: 2rem;
    }

    &-options {
      .btn-upload {
        margin-top: 40%;
      }

      .btn {
        margin: .5rem auto;
      }
    }

  }

  & .band_no_verify {
    width: 100%;
    height: 50px;
    background-color: black;
    color: red;
  }

  &--photo-portada {
    width: 80%;

    height: 197px;

    figure {
      margin: 0 1rem 0 0;
      position: absolute;
      width: 80%;
    }

    img {
      width: 100%;
      height: 197px;
    }

    a {
      position: absolute;
      bottom: 10px;
      right: 10px;
      padding: 2px 10px;
      background: white;
      border: 1px solid black;
      border-radius: 6px;
      cursor: pointer;
      text-decoration: none;
      color: black;

      &:hover {
        color: black;
      }
    }
  }

  &--container {
    display: flex;
    width: 80%;
    margin: 3rem auto;

    .profile {
      &--col1 {
        width: 30%;

        .profile--img--container {
          height: 200px;

          figure {
            margin: 0 1rem 0 0;
            position: absolute;
            width: 200px;
          }

          img {
            width: 100%;
            height: 200px;
          }

          a {
            position: absolute;
            bottom: 10px;
            right: 10px;
            padding: 2px 10px;
            background: white;
            border: 1px solid black;
            border-radius: 6px;
            cursor: pointer;
            text-decoration: none;
            color: black;

            &:hover {
              color: black;
            }
          }
        }
      }

      &--col2 {
        width: 70%;

        .MuiFormControl-root {
          margin: 1rem;
        }

        .button--save {
          position: absolute;
          right: 0;
          top: 0;
        }
      }
    }
  }

  & .how_show {
    width: 300px;

    & .provider--card--container {
      & .MuiPaper-root {
        width: 300px;
        height: 290px;
      }
    }
  }
}

.modal-photo-profile {
  position: relative;

  & .section-buttons {
    position: absolute;
    z-index: 3;
    bottom: 0;
    padding: 2rem 0;
    border-radius: 6px 6px 0;
    width: 100%;
    background: white;
    display: flex;
    justify-content: space-around;
    margin: 0 auto;

    & .button-accept {
      width: 20%;

    }

    & .button-cancel {
      width: 20%;
    }
  }
}

@media only screen and (max-width: 991px) {
  .profile-comercial_public-page {
    .profile_data {
      &__sections {
        ul {
          list-style: none;
          display: flex;
          justify-content: space-between;
          padding: 0;
          margin-bottom: 2rem;
          overflow-x: auto;

          li {
            padding: 1rem;
            border-bottom: .5px solid black;
            min-width: 100px;
            text-align: center;

            a {
              padding: 0;
            }

            &:hover {
              background-color: rgb(202, 202, 202);
              cursor: pointer;
            }
          }
        }
      }

      &__location {
        width: 100%;
        display: block;
        justify-content: space-around;
        margin: 2rem auto;

        & .content {
          margin: 1rem 0;
        }
      }
    }
  }

  .profile--container {
    //display: flex;
    //width: 80%;
    margin: 3rem;

    .profile {
      &--col1 {

        //width: 30%;
        img {
          width: 100%;
          height: 200px;
        }
      }

      &--col2 {
        .MuiFormControl-root {
          margin: 1rem;
        }
      }
    }
  }

  .modal-photo-profile {
    position: relative;

    & .section-buttons {
      position: absolute;
      z-index: 3;
      bottom: 0;
      padding: 2rem 0;
      border-radius: 6px 6px 0;
      width: 100%;
      background: white;

      & .button-accept {
        width: 100%;
        margin-bottom: .5rem;
      }

      & .button-cancel {
        width: 100%;
      }
    }
  }
}