.registerEmail {
  &--container {
    display: flex;
    background-color: white;
    justify-content: space-between;
    &__select-type {
      // width: 100%;
      margin: 20% auto;
      margin-top: 30px;
      &_select {
        width: 100%;
        height: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        &_selector {
          width: 100%;
          margin-left: 3rem !important;
        }
        &_options {
          width: 100%;
          display: flex;
          justify-content: end;
        }
      }
      &_options {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 30%;
        margin: 0 auto;
        button {
          width: 40%;
          height: 60px;
        }
      }
    }
    & .img-promo {
      //border: 1px solid black;
      width: 40%;
      //height: 90vh;
      color: black;

      img {
        //height: 120px;
        background-color: #444;
        /* background-size: cover; */
        width: 100%;
        height: 100%;
        background-position: center center;
      }

      img[class] {
        width: 100%;
      }
    }
  }

  &--inputs {
    width: 40%;
    max-width: 400px;
    margin: 30px auto;
    & .title {
      margin-bottom: 2rem;
    }
    & .MuiOutlinedInput-input {
      padding: 12px 6px;
    }
    & .MuiOutlinedInput-root {
      margin: 12px 0px;
      margin-top: 0px;
      margin-bottom: 14px;
    }
    & .MuiFormControl-root {
      width: 100%;
    }
    input {
      width: 100%;
    }
  }
  & .form-clinica {
    margin: 0 auto;
    width: 60%;
    h3 {
      font-weight: 400;
    }
    .importante {
      padding: 1rem;
      border-radius: 6px;
      margin: 1rem auto;
      background-color: aquamarine;
      p {
        font-weight: 700;
      }
    }
    .info {
      margin: 1rem auto;
      p {
      }
    }
    .options {
      margin: 2rem 0;
      display: flex;
      justify-content: right;
    }
  }
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 992px) {
  .registerEmail {
    &--container {
      display: flex;
      background-color: white;
      justify-content: space-between;
      &__select-type {
        // width: 100%;
        margin: 20% auto;
        margin-top: 30px;
        &_select {
          width: 100%;
          height: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          &_selector {
            width: 100%;
            margin-left: 3rem !important;
          }
          &_options {
            width: 100%;
            display: flex;
            justify-content: end;
          }
        }
        &_options {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 30%;
          margin: 0 auto;
          button {
            width: 40%;
            height: 60px;
          }
        }
      }
      & .img-promo {
        //border: 1px solid black;
        width: 40%;
        //height: 90vh;
        color: black;
  
        img {
          //height: 120px;
          background-color: #444;
          /* background-size: cover; */
          width: 100%;
          height: 100%;
          background-position: center center;
        }
  
        img[class] {
          width: 100%;
        }
      }
    }
  
    &--inputs {
      width: 40%;
      max-width: 400px;
      margin: 30px auto;
      & .title {
        margin-bottom: 2rem;
      }
      & .MuiOutlinedInput-input {
        padding: 12px 6px;
      }
      & .MuiOutlinedInput-root {
        margin: 12px 0px;
        margin-top: 0px;
        margin-bottom: 14px;
      }
      & .MuiFormControl-root {
        width: 100%;
      }
      input {
        width: 100%;
      }
    }
    & .form-clinica {
      margin: 0 auto;
      width: 60%;
      h3 {
        font-weight: 400;
      }
      .importante {
        padding: 1rem;
        border-radius: 6px;
        margin: 1rem auto;
        background-color: aquamarine;
        p {
          font-weight: 700;
        }
      }
      .info {
        margin: 1rem auto;
        p {
        }
      }
      .options {
        margin: 2rem 0;
        display: flex;
        justify-content: right;
      }
    }
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (max-width: 991px) {
  .registerEmail {
    &--container {
      padding: 1rem 2rem;
      height: 90% !important;
      display: block;
      position: relative;
      //height: 100% !important;
      &__select-type {
        width: 100%;
        text-align: center;
        height: 100%;
        position: relative;
        &_select_selector {
          width: 100%;
          margin-left: 3rem;
          & .MuiOutlinedInput-root {
            & .MuiOutlinedInput-input {
              display: flex;
            }
          }
        }
        &_select_options {
          width: 100%;
          top: 0;
          height: 60px;
          // display: -ms-flexbox;
          // display: -webkit-flex;
          // display: flex;
          // -webkit-flex-direction: row;
          // -ms-flex-direction: row;
          // flex-direction: row;
          // -webkit-flex-wrap: nowrap;
          // -ms-flex-wrap: nowrap;
          // flex-wrap: nowrap;
          // -webkit-justify-content: flex-end;
          // -ms-flex-pack: end;
          // justify-content: flex-end;
          // -webkit-align-content: stretch;
          // -ms-flex-line-pack: stretch;
          // align-content: stretch;
          // -webkit-align-items: center;
          // -ms-flex-align: center;
          // align-items: center;
        }
        &_options {
          width: 100%;
          display: block;
          margin: 0 auto;
          button {
            width: 80%;
            height: 60px;
            display: block;
            margin: 25px auto;
          }
        }
        margin-bottom: 30px;
      }
      & .img-promo {
        width: 100%;
        border-radius: 30px;
        position: absolute;
        bottom: 0;
        height: 60%;
        display: none;
        img {
          bottom: 0;
          position: absolute;
          width: 100%;
          height: 100%;
        }
      }
    }
    &--inputs {
      //max-width: 400px;
      //padding: 0 10% 0 10%;
      //@extend: 60px;
      width: 90%;
      margin: 30px auto;
      margin-bottom: 2rem;
      & .title {
        p {
          font-size: 14px;
        }
      }
      & .MuiOutlinedInput-input {
        padding: 12px 6px;
      }
      & .MuiOutlinedInput-root {
        margin: 5px 0px;
      }
      input {
        width: 100%;
      }
      & .button {
        &-primary {
          width: 100%;
          background-color: black;
          color: white;
          margin-top: 2rem;
        }
        &-secondary {
          width: 100%;
          background-color: white;
          color: black;
          margin-top: 1rem;
        }
      }
    }
    & .form-clinica {
      margin: 0 auto;
      width: 100%;
    }
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
