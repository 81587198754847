.calendar-container {
  width: 100%;
  .calendar {
    &-year-options {
      // display: flex;
      // justify-content: space-between;
    }
    &-month-options {
      // display: flex;
      // justify-content: space-between;
    }
  }
   & .fc-daygrid-body .fc-daygrid-body-balanced {
    width: 100% !important ;
    & table{
      width: 100%;
    }
  }
}

h2 {
  margin: 0;
  font-size: 16px;
}

ul {
  margin: 0;
  padding: 0 0 0 1.5em;
}

li {
  margin: 1.5em 0;
  padding: 0;
}

b { /* used for event dates/times */
  margin-right: 3px;
}

.demo-app {
  display: flex;
  min-height: 100%;
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: 14px;
}

.demo-app-sidebar {
  width: 300px;
  line-height: 1.5;
  background: #eaf9ff;
  border-right: 1px solid #d3e2e8;
}

.demo-app-sidebar-section {
  padding: 2em;
}

.demo-app-main {
  flex-grow: 1;
  padding: 3em;
}

.fc { /* the calendar root */
  max-width: 1100px;
  margin: 0 auto;
}