.crear-empleado {
  margin: 0 auto;
  /* width: 80%; */
  /* margin-top: 60px; */
  height: 100%;
  background-color: #e6e6e6;
  //margin-top: 60px;
  &_title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 30px;
    margin-top: 0;
    & p{
      margin: 0;
    }
  }
  &_content {
    width: 80%;
    margin: 0 auto;
    padding-top: 60px;
    padding-bottom: 60px;
    .paper {
      width: 100%;
      height: 100%;
      padding: 15px;
      & .form_parent {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(4, 1fr);
        grid-column-gap: 30px;
        grid-row-gap: 30px;

        margin: 30px;
        &__content--nombre {
          grid-area: 1 / 1 / 2 / 2;
        }
        &__content--apellidos {
          grid-area: 1 / 2 / 2 / 3;
        }
        &__content--email {
          grid-area: 2 / 1 / 3 / 2;
        }
        &__content--password {
          grid-area: 2 / 2 / 3 / 3;
        }
        &__content--rut {
          grid-area: 3 / 1 / 4 / 2;
        }
        &__content--direccion {
          grid-area: 3 / 2 / 4 / 3;
        }
        &__content--contacto {
          grid-area: 4 / 1 / 5 / 2;
        }
        &__content--sucursal {
          grid-area: 4 / 2 / 5 / 3;
        }
        &__content--genero {
          grid-area: 5 / 1 / 6 / 2;
        }
        &__content--10 {
          grid-area: 5 / 2 / 6 / 3;
        }
        &__content--11 {
          grid-area: 6 / 1 / 7 / 2;
        }
        &__content--12 {
          grid-area: 6 / 2 / 7 / 3;
        }
        &__content--13 {
          grid-area: 7 / 1 / 8 / 2;
        }
        &__content--14 {
          grid-area: 7 / 2 / 8 / 3;
        }
        .col {
          &-1,
          &-2 {
            width: 40%;
            margin: 0 auto;
            & .input-form {
              display: block;
              margin: 15px auto;
            }
            & .button {
              margin: 15px auto;
            }
          }
        }
        & .MuiFormControl-root {
          width: 100%;
        }
      }
      & .container_select {
        margin: 0 auto;
        width: 80%;
        padding: 1rem;
        border: 1px solid rgb(167, 167, 167);
        border-radius: 6px;
        margin-bottom: 3rem;

        & .select {
          margin: 15px 5px;
          & .select-especialidades {
            width: 100%;
          }
        }
        & .bubbles {
          margin: auto auto;
          & .bubble {
            margin: 15px 5px;
          }
        }
      }

      & .options {
        display: flex;
        justify-content: flex-end;
        & .button {
          margin: auto 30px;
          width: 140px;
        }
      }
      // & p {
      //   margin: 0;
      //   padding: 5px;
      //   margin-bottom: 2rem;
      // }
    }
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 991px) {
  .crear-empleado {
    //width: 95%;
    &_title {
      display: block;
      p {
        text-align: right;
        padding-right: 1rem;
      }
    }
    &_content {
      width: 95%;
      .paper {
        padding: 15px 0px;
        & .form {
          &_parent {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            grid-template-rows: repeat(8, 1fr);
            grid-column-gap: 0px;
            grid-row-gap: 20px;

            margin: 30px;
            // display: flex;
            &__content--nombre {
              grid-area: 1 / 1 / 1 / 2;
            }
            &__content--apellidos {
              grid-area: 2 / 1 / 2 / 2;
            }
            &__content--rut {
              grid-area: 4 / 1 / 4 / 2;
            }
            &__content--email {
              grid-area: 3 / 1 / 3 / 2;
            }
            &__content--password {
              grid-area: 5 / 1 / 5 / 2;
            }
            &__content--direccion {
              grid-area: 5 / 1 / 5 / 2;
            }
            &__content--contacto {
              grid-area: 6 / 1 / 6 / 2;
            }
            &__content--sucursal {
              grid-area: 7 / 1 / 7 / 2;
            }
            &__content--genero {
              grid-area: 8 / 1 / 8 / 2;
            }
            &__content--10 {
              grid-area: 5 / 2 / 6 / 3;
            }
            &__content--11 {
              grid-area: 6 / 1 / 7 / 2;
            }
            &__content--12 {
              grid-area: 6 / 2 / 7 / 3;
            }
            &__content--13 {
              grid-area: 7 / 1 / 8 / 2;
            }
            &__content--14 {
              grid-area: 7 / 2 / 8 / 3;
            }
          }
          .col {
            &-1,
            &-2 {
              width: 100%;
            }
          }
        }
        & .container_select {
          margin: 0 30px;
          margin-bottom: 30px;
          width: auto;
        }
      }
    }
  }
}
