@import "../../Styles/main.scss";
.modal-contact {
  background-color: white;
  padding: 2rem 4rem;
  border-radius: 10px;
  border: none;
  outline: none;
  //width: 84vw;
  &:focus {
    outline: none;
  }
  .form-contact {
    div {
      padding: 0.3rem 0;
    }
  }
}
.provider--card--container {
  color: $my-sin;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  .card_container {
    &:hover {
      -webkit-box-shadow: 0px 0px 4px 3px rgba(94,94,94,1);
      -moz-box-shadow: 0px 0px 4px 3px rgba(94,94,94,1);
      box-shadow: 0px 0px 4px 3px rgba(94,94,94,1);
    }
  }

  .MuiCardHeader-root {
    .MuiCardHeader-avatar {
      & .avatar-name {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: white;
        border-radius: 30px;
        padding: 0 15px;
      }
    }
    .MuiCardHeader-action {
      .button {
        background-color: white;
        border-radius: 30px;
      }
    }
  }
  & .MuiGrid-item {
    padding: 0;
  }
  &:hover {
    .MuiCardActions-root {
    }
  }
  #simple-menu {
    & .MuiPopover-paper {
      padding: 0px 50px;
    }
  }
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (max-width: 991px) {
  & .modal-contact {
    background-color: white;
    padding: 2rem 4rem;
    border-radius: 10px;
    border: none;
    outline: none;
    &:focus {
      outline: none;
    }
    .form-contact {
    }
  }
  .provider--card--container {
    //padding: 0.5rem;
    color: $my-sin;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right;
    height: 100%;
    .card-actions {
      background-color: rgba(218, 218, 218, 0.75);
      & .show_more {
        border-bottom-right-radius: 16px;
        background-color: white;
      }
      & .prov-like {
        color: red;
      }
    }
    & .MuiPaper-root {
      //width: 66vw;
      height: 100% !important;
    }
    & .MuiCardActions-root {
      display: flex;
      justify-content: space-between;
    }

    &:hover {
      .MuiCardActions-root {
      }
    }
  }
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  & .modal-contact {
    background-color: white;
    padding: 2rem 4rem;
    border-radius: 10px;
    border: none;
    outline: none;
    &:focus {
      outline: none;
    }
    .form-contact {
    }
  }
  .provider--card--container {
    //padding: 0.5rem;
    color: $my-sin;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right;

    .MuiCardHeader-root {
      padding: 0;
      align-items: flex-start;
      .MuiCardHeader-avatar {
        & .avatar-name {
          display: flex;
          justify-content: center;
          //align-items: center;
          background-color: white;
          border-radius: 0px 0px 30px 0px;
          padding: 0 5px;
          -webkit-box-shadow: 0px 14px 33px -10px rgb(0, 0, 0);
          -moz-box-shadow: 0px 14px 33px -10px rgb(0, 0, 0);
          box-shadow: 0px 14px 33px -10px rgb(0, 0, 0);
          .name {
            margin: 0.5rem;
            margin-right: 1.5rem;
          }
          .MuiAvatar-root {
            width: 30px;
            height: 30px;
          }
        }
      }
    }

    & .MuiCardHeader-action {
      display: none;
      padding: 8px;
      // background-color: white;
      // padding: 16px;
      & .MuiButtonBase-root {
        -webkit-box-shadow: 0px 14px 33px -10px rgb(0, 0, 0);
        -moz-box-shadow: 0px 14px 33px -10px rgb(0, 0, 0);
        box-shadow: 0px 14px 33px -10px rgb(0, 0, 0);
      }
    }

    & .MuiCardMedia-root {
      padding-top: 0px;
      height: 38px;
    }
    .card-actions {
      display: none;
      background-color: rgba(218, 218, 218, 0.75);
      & .show_more {
        border-top-left-radius: 24px;
        background-color: white;
        -webkit-box-shadow: 0px 14px 33px -10px rgb(0, 0, 0);
        -moz-box-shadow: 0px 14px 33px -10px rgb(0, 0, 0);
        box-shadow: 0px 14px 33px -10px rgb(0, 0, 0);
      }
      & .prov-like {
        color: red;
      }
    }
    & .MuiPaper-root {
      width: 100%;
      border-radius: 0px;
      position: absolute;
      height: 100%;
    }
    & .MuiCardActions-root {
      display: flex;
      justify-content: space-between;
      position: absolute;
      bottom: 0;
      width: 100%;
      padding: 0;
      display: none;
    }

    &:hover {
      .card-actions{
        display: flex;
        background-color: rgba(218, 218, 218, 0.75);
      & .show_more {
        border-top-left-radius: 24px;
        background-color: white;
        -webkit-box-shadow: 0px 14px 33px -10px rgb(0, 0, 0);
        -moz-box-shadow: 0px 14px 33px -10px rgb(0, 0, 0);
        box-shadow: 0px 14px 33px -10px rgb(0, 0, 0);
      }
      & .prov-like {
        color: red;
      }
      }
      & .MuiCardActions-root {
        display: flex;
        justify-content: space-between;
        position: absolute;
        bottom: 0;
        width: 100%;
        padding: 0
      }
    }
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .provider--card--container {
    width: 100%;
    & .MuiPaper-root {
      max-width: 100%;
    }
  }
}
