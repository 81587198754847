@import "../../Styles/main.scss";
.profile-professional-container {
  background-color: $primary;
  color: $black;
  .body {
    margin: 2rem;
  }
  .options {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    .button-back {
      background-color: $secondary-dark;
      color: $black;
      padding: 10px 40px;
    }
  }
  .title {
    //display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    h1 {
      margin: 0;
    }
    h6 {
      margin: 0;
      margin-bottom: 30px;
    }
  }
  .photo-portada {
    width: 100%;
    height: 250px;
    background-size: cover;
    background-position: center;
  }
  .section {
    &_agenda {
      background-color: white;
      border-radius: 6px;
      padding: 2rem;
      margin: 1rem;
      //background-color: rgb(177, 177, 177);
      & .header {
        display: flex;
        align-items: center;
        h4 {
          margin: 0;
        }
      }
      & .content {
        &.expand {
            min-height: 425px;
          background-color: white;
          display: block;
          width: 100%;
          //margin-left: 20%;
        }
        &.collapse {
          display: none;
        }
        .options {
          display: flex;
          justify-content: space-between;
          height: 100%;
          min-height: 425px;
          .option {
            width: 33%;
            border: 1px solid black;
            border-radius: 6px;
            height: 100%;
            min-height: 425px;
            &_header{
                padding:.5rem;
            }
          }
        }
      }
      & .fc .fc-toolbar.fc-header-toolbar{
          margin: 0;
      }
    }
    &_map {
      padding: 2rem;
      background: white;
      margin: 1rem;
      border-radius: 6px;
      .header {
        padding-bottom: 1rem;
        h4 {
          margin: 0;
        }
      }
      & .content {
        display: flex;
        justify-content: space-around;
      }
    }
    &_gallery {
      padding: 2rem;
      background: white;
      margin: 1rem;
      border-radius: 6px;
      .header {
      }
      .content {
        .gallery {
          & .photo {
            min-width: 300px;
            min-height: 400px;
          }
        }
      }
    }
  }
}

.modal_content_message{
    padding: 2rem;
    .emisor{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: 1rem;
        margin-bottom: 1rem;

    }
    .mensaje{
        margin-top: 1rem;
        margin-bottom: 1rem;

        textarea{
            width: 100%;
            height: 100px;
        }
    }
    .options{
        margin-top: 2rem;
        // margin-bottom: 1rem;
        display: flex;
        justify-content:flex-end ;
        p{
            margin: auto 2rem;
            cursor: pointer;
        }
        Button{
            margin: auto 2rem;
            
        }
    }
}