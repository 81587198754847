.card-clinica_container {
  width: 300px;
  height: 300px;
  // -webkit-box-shadow: 0px 4px 4px 2px rgba(0, 0, 0, 0.47);
  // box-shadow: 0px 4px 4px 2px rgba(0, 0, 0, 0.47);
  // border-radius: 6px;
  border: 1px solid rgb(107, 107, 107);
  border-radius: 6px 6px 0 0;
  position: relative;

  &__title {
    height: 13%;
    background: rgb(255, 255, 255);
    background: linear-gradient(180deg,
        rgba(255, 255, 255, 1) 61%,
        rgba(0, 212, 255, 0) 100%);
    border-radius: 6px 6px 0 0;

    p {
      margin: 0;
      padding: 4px 4px;
      color: #365b6d;
      font-weight: 600;
      text-align: center;
    }
  }

  &__footer {
    height: 20%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    bottom: 0;
    width: 100%;
    border-radius: 0 0 6px 6px;
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .card-clinica_container {}
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .card-clinica_container {}
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .card-clinica_container {}
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .card-clinica_container {}
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .card-clinica_container {}
}