.profile-page {
  margin: 0.5rem auto;
  width: 80%;

  & .band_no_verify {
    width: 100%;
    height: 50px;
    background-color: black;
    color: red;
  }

  &--photo-portada {
    width: 80%;

    height: 15vh;

    figure {
      margin: 0 1rem 0 0;
      position: absolute;
      width: 80%;
    }

    img {
      width: 100%;
      height: 15vh;
    }

    a {
      position: absolute;
      bottom: 10px;
      right: 10px;
      padding: 2px 10px;
      background: white;
      border: 1px solid black;
      border-radius: 6px;
      cursor: pointer;
      text-decoration: none;
      color: black;

      &:hover {
        color: black;
      }
    }
  }

  &--container {
    display: flex;
    //width: 80%;
    margin: 0.5rem auto;

    .profile {
      &--col1 {
        //swidth: 30%;
        margin: 0.5rem;
        -webkit-box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
          0px 1px 1px 0px rgba(0, 0, 0, 0.14),
          0px 1px 3px 0px rgba(0, 0, 0, 0.12);
        -moz-box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
          0px 1px 1px 0px rgba(0, 0, 0, 0.14),
          0px 1px 3px 0px rgba(0, 0, 0, 0.12);
        box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
          0px 1px 1px 0px rgba(0, 0, 0, 0.14),
          0px 1px 3px 0px rgba(0, 0, 0, 0.12);
        border-radius: 5px;
        padding: 1rem;

        .profile_container {
          & .btn-container {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            margin: 0.5rem auto;

            label {
              width: 100%;
            }

            .btn-upload {
              & .MuiButton-label {
                font-size: 10px;
              }
            }
          }

          & .profile--img {

            &--container {
              display: flex;
              justify-content: center;
              align-items: center;
              position: relative;
              cursor: pointer;

              .tooltiptext {
                visibility: hidden;
                width: 120px;
                background-color: black;
                color: #fff;
                text-align: center;
                padding: 5px 0;
                border-radius: 6px;

                /* Position the tooltip text - see examples below! */
                position: absolute;
                bottom: 0;
                z-index: 1;
              }

              &:hover {
                .tooltiptext {
                  visibility: visible;
                }
              }

              figure {
                width: 100%;
                margin: 0;
              }

              .profile--img {
                width: 200px;
                height: 200px;

                &:hover {
                  filter: contrast(0.5);

                }
              }

              img {
                width: 200px;
                height: 200px;
              }
            }
          }
        }
      }

      &--col2 {
        width: 70%;
        margin: 0.5rem;
        -webkit-box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
        0px 1px 1px 0px rgba(0, 0, 0, 0.14),
        0px 1px 3px 0px rgba(0, 0, 0, 0.12);
        -moz-box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
        0px 1px 1px 0px rgba(0, 0, 0, 0.14),
        0px 1px 3px 0px rgba(0, 0, 0, 0.12);
        box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
        0px 1px 1px 0px rgba(0, 0, 0, 0.14),
        0px 1px 3px 0px rgba(0, 0, 0, 0.12);
        border-radius: 5px;
        padding: 2rem;
        .MuiFormControl-root {
          margin: 1rem 0;
        }

        .button--save {
          position: absolute;
          right: 0;
          top: 0;
        }
        .container {
          position: relative;
         
        }

        .title {
          h3 {
            margin: 1rem;
          }
        }

        & .name-lastname{
          display: flex;
          & .MuiFormControl-root{
            width: 95%;
            &:first-child{
              margin-right: 1rem;
            }
          }
        }
        & .clinica-contact{
          display: flex;
          & .MuiFormControl-root{
            width: 95%;
            &:first-child{
              margin-right: 1rem;
            }
          }
        }
        & .region_commune {
          display: flex;
          & .MuiFormControl-root{
            width: 95%;
            &:first-child{
              margin-right: 1rem;
            }
          }
        }
      }
    }

   
  }
  & .button_container{
    display: none;
    
  }
}

@media only screen and (max-width: 991px) {
  .profile-page {
    width: 95% !important;
    margin: 0 auto;
    margin-top: 30px;

    &--container {
      display: block !important;
      margin: 3rem;
      .profile {
        &--col1 {
          img {
            width: 100%;
            height: 200px;
          }
        }

        &--col2 {
          width: auto !important;
          padding: 2rem;
          padding-left: 0 !important;
          .MuiFormControl-root {
            margin: 1rem;
            width: 100%;
          }

          .button--save {
            display: none;
          }
          & .name-lastname{
            display: block;
            & .MuiFormControl-root{
              width: 100%;
              &:first-child{
                // margin-right: 1rem;
              }
            }
          }
          & .clinica-contact{
            display: block;
            & .MuiFormControl-root{
              width: 100%;
              &:first-child{
                // margin-right: 1rem;
              }
            }
          }
          & .region_commune {
            display: block;
            & .MuiFormControl-root{
              width: 100%;
              &:first-child{
                // margin-right: 1rem;
              }
            }
          }
        }
      }
      
     
    }
    & .button_container{
      display: flex;
      justify-content: center;
      & .button__save--mobile {
        display: block;
        width: 100%;
        margin-top: 1rem;
        padding: 2rem;
      }
    }
    & .menu-styled {
      & .menu-item {
        & p {
          margin: 0;
          padding: 0;
        }
      }
    }

  }
}